<script lang="ts" setup>
import { PropType } from 'vue'

const props = defineProps({
    layers: {
        type: Array as PropType<
            {
                title: string
                path?: string
            }[]
        >,
        required: true,
    },
    container: {
        type: Boolean,
        default: true,
    },
})

const capitalize = (str: string) => {
    const lowered_str = str.toLowerCase()
    return lowered_str.charAt(0).toUpperCase() + lowered_str.slice(1)
}
</script>

<template>
    <ol class="breadcrumbs" :class="props.container ? 'container' : ''">
        <li v-for="(layer, key) in props.layers" :key="key">
            <div v-if="key !== 0" class="right-flow">
                <UnoIcon class="i-mdi:chevron-right" />
            </div>
            <NuxtLink
                no-prefetch
                v-if="layer.path && layer.title && layer.title.toLowerCase() === 'home'"
                :to="layer.path"
                rel="follow"
                class="breadcrumbs__homeicon"
            >
                <UnoIcon class="i-carbon:home" />
            </NuxtLink>
            <NuxtLink
                no-prefetch
                v-else-if="layer.path && key + 1 !== props.layers.length"
                :to="layer.path"
                rel="follow"
            >
                <span v-if="layer.title">{{ capitalize(layer.title) }}</span>
            </NuxtLink>
            <p v-else-if="layer.title">
                {{ capitalize(layer.title) }}
            </p>
        </li>
    </ol>
</template>

<style lang="postcss" scoped>
.breadcrumbs {
    @apply flex space-x-1 relative my-2 lg:my-3 font-Inter;
    &__homeicon {
        @apply flex mt-1 md:mt-2 transition hover:text-[#EBAF00];
    }
    li {
        @apply relative flex items-center text-[#0F0F0F] text-sm md:text-lg gap-1;
    }
    a {
        @apply line-clamp-1 lg:hover:underline;
    }
    .right-flow {
        @apply self-center text-xl mt-2 text-[#DEDEDE];
    }
    p {
        @apply cursor-default line-clamp-1 font-semibold;
    }
    span {
        @apply text-[#858585] font-medium;
    }
}
</style>
